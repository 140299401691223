/* Radio Buttons
   ========================================================================== */

// Remove default Radio Buttons
.ml-radio:not(:checked),
.ml-radio:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

.ml-radio:not(:checked) + label,
.ml-radio:checked + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  transition: .28s ease;

  -khtml-user-select: none; /* webkit (konqueror) browsers */
  user-select: none;
}

.ml-radio + label:before,
.ml-radio + label:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  transition: .28s ease;
}

/* Unchecked styles */
.ml-radio:not(:checked) + label:before,
.ml-radio:not(:checked) + label:after,
.ml-radio:checked + label:before,
.ml-radio:checked + label:after,
.ml-radio.with-gap:checked + label:before,
.ml-radio.with-gap:checked + label:after {
  border-radius: 50%;
}

.ml-radio:not(:checked) + label:before,
.ml-radio:not(:checked) + label:after {
  border: 2px solid $radio-empty-color;
}

.ml-radio:not(:checked) + label:after {
  transform: scale(0);
}

/* Checked styles */
.ml-radio:checked + label:before {
  border: 2px solid transparent;
}

.ml-radio:checked + label:after,
.ml-radio.with-gap:checked + label:before,
.ml-radio.with-gap:checked + label:after {
  border: $radio-border;
}

.ml-radio:checked + label:after,
.ml-radio.with-gap:checked + label:after {
  background-color: $radio-fill-color;
}

.ml-radio:checked + label:after {
  transform: scale(1.02);
}

/* Radio With gap */
.ml-radio.with-gap:checked + label:after {
  transform: scale(.5);
}

/* Focused styles */
.ml-radio.tabbed:focus + label:before {
  box-shadow: 0 0 0 10px rgba(0,0,0,.1);
}

/* Disabled Radio With gap */
.ml-radio.with-gap:disabled:checked + label:before {
  border: 2px solid $input-disabled-color;
}

.ml-radio.with-gap:disabled:checked + label:after {
  border: none;
  background-color: $input-disabled-color;
}

/* Disabled style */
.ml-radio:disabled:not(:checked) + label:before,
.ml-radio:disabled:checked + label:before {
  background-color: transparent;
  border-color: $input-disabled-color;
}

.ml-radio:disabled + label {
  color: $input-disabled-color;
}

.ml-radio:disabled:not(:checked) + label:before {
  border-color: $input-disabled-color;
}

.ml-radio:disabled:checked + label:after {
  background-color: $input-disabled-color;
  border-color: $input-disabled-solid-color;
}
